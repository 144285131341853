// This file is intended to be used outside of the player-lib

import { PlayerError } from './player-errors';
import { AnalyticsMode, Breakpoint } from './player.interface';
import { HiveDebugLevel, HiveTechName, HiveToken } from './utils/hive.interface';

export type TextTrackType = 'subtitles';

export type PlayerCallback = () => void;
export type PlayerTimedMetadataCallback = (event: TimedMetadataEvent) => void;
export type PlayerDurationChangeCallback = (event: DurationChangeEvent) => void;
export type PlayerErrorCallback = (error: PlayerError) => void;

export interface PlayerTextTrack {
  id: string;
  kind: TextTrackType;
  srclang: string;
  label: string;
  src: string;
}

export interface VideoMetadataConfig {
  videoId?: string;
  title?: string;
  /**
   * The description is used outside the player, in the "playout-player".
   * As "description" is the only config for outside, we added it to the main structure.
   * default: empty
   * */
  description?: string;
}

/**
 * fluid - The player will scale to fit its container at the video's intrinsic aspect ratio.
 *
 * fill - The player will fill its container, adjusting its dimensions to match your video’s aspect ratio.
 *
 * none - The player will have the fixed width and height of the original video.
 */
export type VideoLayout = 'fluid' | 'fill' | 'none';

export interface SettingsConfig {
  /**
   * Makes the player layout in different ways
   * - fluid: (default) The player will scale to fit its container at the video's intrinsic aspect ratio.
   * - fill: The player will fill its container, adjusting its dimensions to match your video’s aspect ratio.
   * - none: The player will have the fixed width and height of the original video.
   *
   * default: fluid
   * */
  videoLayout?: VideoLayout;
  /**
   * The aspect ratio of the player. When no aspect ratio is specified, the player will scale to fit its container at the video's intrinsic aspect ratio.
   * Setting this to a specific value (16:9, 4:3) will always make the player responsive.
   * default: Auto
   * */
  aspectRatio?: '16:9' | '4:3' | 'Auto';
  /**
   * A hex value for the Player's primary color.
   * For example: #38761d
   * default: empty
   * */
  primaryColor?: string;

  /**
   * The player's color theme. This currently only affects the player's menus.
   *
   * @default dark
   */
  theme?: 'dark' | 'light';

  /**
   * The background color of the control bar (shadow at the bottom of the player).
   *
   * @default #333333
   */
  controlBarColor?: string;

  /**
   * The color of the icons in the control bar.
   *
   * @default #FFFFFF
   */
  iconColor?: string;

  /**
   * The URL for the logo.
   * default: empty
   * */
  logoUrl?: string;
  /**
   * Player adjusts its UI based on its dimensions.
   * When the aspect ratio is configured to a specific value, setting this will have no effect (value will be true).
   * default: true
   * */
  responsive?: boolean;
  /**
   * Turn on/off autoplay.
   * default: false
   * */
  autoplay?: boolean;
  /**
   * Turn on/off looping of the video.
   * default: false
   * */
  looping?: boolean;
  /**
   * Mute the video.
   * default: false
   * */
  muted?: boolean;
  /**
   * When true, no user interface will be rendered. All other user interface related options such as "liveUI" will have no effect.
   * default: false
   * */
  noUI?: boolean;
  /**
   * Show or hide the UI for live streams
   * default: false
   * */
  liveUI?: boolean;
  /**
   * Show or hide audio-only waveform animation or the audio-only image.
   *
   * default: false
   * */
  audioOnly?: boolean;
  /**
   * The URL for the audio-only image.
   *
   * If "audioOnly" is enabled, the image will cover the video.
   * If "audioOnly" is enabled and the audioOnlyImageUrl is not set,
   * the default "Wave-Animation" covers the video.
   *
   * default:
   *  The default is a "empty" string.
   * */
  audioOnlyImageUrl?: string;
  /**
   * Player’s volume in the range of 0 (silent) to 1.0 (max volume).
   * default: 0.7
   * */
  volume?: number;
  /**
   * Show the video trimming controls.
   * default: false
   * */
  trimmable?: boolean;
  /**
   * Hide the logo.
   * default: false
   * */
  hideLogo?: boolean;
  /**
   * Hide the title.
   * default: false
   * */
  hideTitle?: boolean;
  /**
   * Hide the play button.
   * default: false
   * */
  hidePlayButton?: boolean;
  /**
   * Hide the timeline.
   * default: false
   * */
  hideTimeline?: boolean;
  /**
   * Hide the volume slider.
   * default: false
   * */
  hideVolumeInput?: boolean;
  /**
   * Hide the timer.
   * default: false
   * */
  hideTimer?: boolean;
  /**
   * Hide the loop button.
   * default: false
   * */
  hideLoopButton?: boolean;
  /**
   * @deprecated Use {@link hideLanguageMenu} and {@link hideSettingsMenu} instead.
   *
   *
   * Hide the playback settings and subtitles.
   * default: false
   * */
  hideMenu?: boolean;
  /**
   * Hide the fullscreen button.
   * default: false
   * */
  hideFullscreenButton?: boolean;
  /**
   * Hide the playback speed button in the settings menu.
   * default: false
   * */
  hidePlaybackSpeed?: boolean;
  /**
   * Hide the settings menu.
   * default: false
   */
  hideSettingsMenu?: boolean;
  /**
   * Hide the subtitles / audio menu.
   * default: false
   */
  hideLanguageMenu?: boolean;
  /**
   * Hide the big play button shown when the player is intialy paused.
   * default: false
   */
  hideBigPlayButton?: boolean;
  /**
   * Hide the chapter list button.
   * default: false
   */
  hideChaptersButton?: boolean;
  /**
   * Hide the steps buttons.
   * default: false
   * */
  stepButtonsAreVisible?: boolean;
  /**
   * Preferred initial quality for
   * progressive sources
   */
  preferredQuality?: string;
  /**
   * The max number of times
   * that a playlist will retry loading
   * default: 15
   */
  maxPlaylistRetries?: number;

  /**
   * Overrides settings (and the player's default behaviours) based on the player width. See {@link Breakpoint} type for more details.
   */
  breakpoints?: Breakpoint[];

  /**
   * Enables the subtitle by default.
   *
   * default: false
   * */
  subtitleEnabled?: boolean;

  /**
   * The fallback language when the subtitle is enabled and
   * the browser language does not match any available subtitle.
   *
   * default: 'en'
   * */
  subtitleFallbackLanguage?: string;
}

export interface SourceObject {
  src: string;
  type: SourceType;
}

export interface ProgressiveSources {
  [index: string]: SourceObject[];
}

export interface PlayerSources {
  hls?: SourceObject;
  progressive?: ProgressiveSources;
}

export interface TextTrackConfig {
  id: string;
  kind: TextTrackType;
  srclang: string;
  label: string;
  src: string;
}

export interface PluginsConfig {
  hive?: HivePluginConfig;
  ramp?: RampPluginConfig;
  youboraAnalytics?: YouboraAnalyticsConfig;
}

/**
 * required:
 *  - accountCode: the account id of the customer
 *  - userName: unique id like a visitor-id, fingerprint
 *
 * defaults:
 * - 'content.customDimension.4': document.location.href
 * - 'content.customDimension.5': to track the referrer
 *
 * example:
 * {
 *   accountCode: '12def322',
 *   username: 'ce223c',
 *   "content.customDimension.1": '12345678
 * }
 *
 * This file is similar to youboralib.YouboraPluginConfig,
 * except some props that we want to hide
 */
export interface YouboraAnalyticsConfig {
  accountCode: string;
  'user.name': string;
  host?: string;
  'device.id'?: string;
  'device.isAnonymous'?: boolean;
  'network.ip'?: string;
  'content.id'?: string;
  'content.title'?: string;
  'content.channel'?: string;
  'content.language'?: string;
  'content.customDimension.1'?: string;
  'content.customDimension.2'?: string;
  'content.customDimension.3'?: string;
  'content.customDimension.4'?: string;
  'content.customDimension.5'?: string;
  'content.customDimension.6'?: string;
  'content.customDimension.7'?: string;
  'content.customDimension.8'?: string;
  'content.customDimension.9'?: string;
  'content.customDimension.10'?: string;
  'content.customDimension.11'?: string;
  'content.customDimension.12'?: string;
  'content.customDimension.13'?: string;
  'content.customDimension.14'?: string;
  'content.customDimension.15'?: string;
  'content.customDimension.16'?: string;
  'content.customDimension.17'?: string;
  'content.customDimension.18'?: string;
  'content.customDimension.19'?: string;
  'content.customDimension.20'?: string;
}

/**
 * omnicacheUris are for the caching servers and vbrick props for the p2p streaming
 */
export interface RampPluginConfig {
  /** This is the event id and should be unique for each event */
  id: string;
  /** A comma separated list of URIs */
  omnicacheUris?: string;
  /** The Host name to support P2P technology */
  vbrickHostName?: string;
  /** The Jwt key to support P2P technology */
  vbrickJwtKeyName?: string;
  /** The Api key to support P2P technology */
  vbrickApiKey?: string;
  /** The Api secret to support P2P technology */
  vbrickApiSecret?: string;
  /** Title of event. It’s not mandatory but nice to have it for analytics display */
  title?: string;
  /** Turn on or off the logging */
  debug?: boolean;
}

export interface HivePluginConfig {
  /**
   * The url of the ticket for initialize a Hive Session
   * @deprecated Use "token" instead
   * */
  ticket?: string;
  /** Token Object for initialize a Hive Session */
  token?: HiveToken;
  /**
   * Types of distributions
   * default: ['HiveJava', 'HiveJS', 'StatsJS']
   *
   * HiveJava tech: distributes the video through the Java proxy client, works on every browser but supports only one video at the time.
   * HiveJS tech: distributes the video between browsers using WebRTC, works only on WebRTC Datachannel enabled browsers, supports one video per page.
   * StatsJS tech: no video distribution, only sends telemetry data for Insights analysis but works in every browser.
   *
   * https://download.hivestreaming.com/media-player/docs/videojs/docs/index.html#hivecomm.techs
   * */
  techOrder?: HiveTechName[];

  /**
   * Event name. This name is used in lieu of the Event/Video ID
   * on the Hive Portal (Video Listing, Video Monitor, Insights)
   * if no name is passed in the ticket creation metadata.
   *
   * Since JWT Authorization does not use a ticket,
   * this value SHOULD be provided.
   */
  eventName?: string;
  /**
   * Configure the debug level for the Hive Player Plugin.
   *
   * default: 'notice'
   */
  debugLevel?: HiveDebugLevel;
}

export type WatermarkPosition =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right';

export interface WatermarkConfig {
  content: string;
  position?: WatermarkPosition;
  color?: string;
  textStroke?: string;
  opacity?: number;
}

export interface ChaptersConfig {
  [key: string]: ChapterConfig[];
}
export interface ChapterConfig {
  start: number;
  title: string;
  description?: string | null;
  thumbnail: string;
}

export interface PlayerConfig {
  /** The URL for the thumbnail picture. */
  poster?: string;
  /** The sources for the video. HLS and progressive sources are supported. */
  sources: PlayerSources;
  /** Optional metadata for the video. */
  metadata?: VideoMetadataConfig;
  /** Settings to hide / show player buttons, display a custom logo, etc. */
  settings?: SettingsConfig;
  /** An array of subtitles. */
  tracks?: TextTrackConfig[];
  /** Information about the timeline preview images. */
  storyboard?: StoryboardConfig;
  /** A list of chapters. */
  chapters?: ChaptersConfig;
  /** Default language for the player. */
  lng?: string;
  /** Player Plugin Configs */
  plugins?: PluginsConfig;
  /** A callback function to be executed when the Player is ready. */
  onReady?: (api: PlayerApi) => void;
  /** A callback function to be executed when the visibility of the player overlay changes. */
  onOverlayVisibilityChange?: (hidden: boolean) => void;
  /** An object with the Watermark configuration. */
  watermark?: WatermarkConfig;
  /**
   * Sets the Analytics mode
   * @type {AnalyticsMode}
   * @default minimal
   * */
  analyticsMode?: AnalyticsMode;
  /** A list of the possible sources available to download. If at least one source is defined, the download menu will be available */
  downloadSources?: DownloadSource[];
}

export type DownloadSource = {
  id: string;
  label: string;
  fileSize: number;
  url: string;
};

export interface StoryboardConfig {
  sources: string[];
  interval: number;
  width: number;
  height: number;
  spritesPerRow: number;
}

export interface PlayerApi {
  /**
   * Attempts to begin playback.
   * @throws if playing fails
   */
  play(): Promise<void>;
  /**
   * Pauses playback.
   */
  pause(): void;
  /**
   * Sets the current time of the player.
   * @param {number} time - Time in seconds.
   */
  setCurrentTime(time: number): void;
  /**
   * Returns the current time of the player in seconds.
   * Note: If called immediately after setCurrentTime, the change would not be reflected.
   */
  getCurrentTime(): number;
  /**
   * Sets the current start time for the trimmer.
   * @param {number} milliseconds - The time to be set as start time for the trimmer.
   */
  setTrimStartTime(milliseconds: number): void;
  /**
   * Returns the current start time for the trimmer.
   */
  getTrimStartTime(): number;
  /**
   * Sets the current end time for the trimmer.
   * @param {number} milliseconds - The time to be set as end time for the trimmer.
   */
  setTrimEndTime(milliseconds: number): void;
  /**
   * Returns the current end time for the trimmer.
   */
  getTrimEndTime(): number;
  /**
   * Adds a subtitle to the player.
   * @param {PlayerTextTrack} subtitle - The subtitle track to be added.
   */
  addSubtitle(subtitle: PlayerTextTrack): Promise<void>;
  /**
   * Sets a specific subtitle as selected.
   * @param {string} id - The subtitle's id.
   */
  setSelectedSubtitle(id: string): void;
  /**
   * Removes every subtitle from the player.
   */
  removeSubtitles(): void;
  /**
   * Turns mute on or off.
   */
  setMuted(value: boolean): void;
  /**
   * Returns whether volume is currently muted.
   */
  getMuted(): boolean;
  /**
   * Returns whether playback is currently paused or has yet to play.
   */
  getPaused(): boolean;
  /**
   * Sets the player’s volume in the range of 0 (silent) to 1.0 (max volume). Unmutes a muted player.
   * @param {number} Value between 0 and 1.
   */
  setVolume(value: number): void;
  /**
   * Returns the current volume between 0 and 1.
   */
  getVolume(): number;
  /**
   * Returns if the media has ended. - True indicates that the media has ended. - False indicates that the media has not ended.
   */
  hasEnded(): boolean;
  /**
   * The duration of the media in seconds or 0 if there is no duration.
   */
  getDuration(): number;
  /**
   * Adds an event listener to the player.
   * @param {PlayerEvent} type - The event to bind to.
   * @param {callback} listener - The event listener.
   */
  addEventListener<K extends keyof ListenerEventMap>(type: K, listener: ListenerEventMap[K]): void;

  /**
   * Removes an event listener from the player.
   * @param {PlayerEvent} type - The event to remove the bind from.
   * @param {callback} listener - The event listener to be removed.
   */
  removeEventListener<K extends keyof ListenerEventMap>(type: K, listener: ListenerEventMap[K]): void;
}

export interface TimedMetadataEvent {
  /**
   * CuePoint metadata data.
   */
  metadata: CueData[];
}

export interface DurationChangeEvent {
  /**
   * The new duration of the media in seconds.
   */
  duration: number;
}

/**
 * Specialized Cue-type for data
 * e.g. used in TIMED_METADATA Events
 */
export interface CueData {
  start: number;
  end: number;
  value: string;
}

export enum SourceType {
  MP4 = 'video/mp4',
  WEBM = 'video/webm',
  APPLE_HLS = 'application/vnd.apple.mpegurl',
  HLS = 'application/x-mpegURL',
}

export enum PlayerEvent {
  /**
   * Triggered when the element is no longer paused.
   * Fired after the play() method has returned,
   * or when the autoplay attribute has caused playback to begin.
   */
  PLAY = 'play',
  /**
   * Triggered when the element has been paused.
   * Fired after the pause() method has returned.
   */
  PAUSE = 'pause',
  /**
   * Triggered when a cue-point was found as part of the playback.
   *
   * Example for consuming the data:
   *
   * function timedMetadataHandler(event: TimedMetadataEvent){
   *   console.log('metadata:', JSON.parse(event.metadata[0]));
   * }
   */
  TIMED_METADATA = 'timedmetadata',
  /**
   * Triggered when the current playback position changed
   * as part of normal playback or in an
   * especially interesting way, for example discontinuously.
   */
  TIME_UPDATE = 'timeupdate',
  /**
   * Triggered when the seeking IDL attribute changed to false
   * after the current playback position was changed.
   */
  SEEKED = 'seeked',
  /**
   * Triggered when the playback has stopped because
   * the end of the media resource was reached.
   */
  ENDED = 'ended',
  /**
   * Triggered when the media metadata has been loaded.
   */
  LOADED_METADATA = 'loadedmetadata',
  /**
   * Triggered when the volume has changed.
   */
  VOLUME_CHANGE = 'volumechange',
  /**
   * Triggered when an error occurs such as, no network connection, invalid source, etc.
   */
  ERROR = 'error',
  /**
   * Triggered when the duration of the media has changed.
   */
  DURATION_CHANGE = 'durationchange',
}
export interface ListenerEventMap {
  [PlayerEvent.PLAY]: PlayerCallback;
  [PlayerEvent.PAUSE]: PlayerCallback;
  [PlayerEvent.TIMED_METADATA]: PlayerTimedMetadataCallback;
  [PlayerEvent.TIME_UPDATE]: PlayerCallback;
  [PlayerEvent.SEEKED]: PlayerCallback;
  [PlayerEvent.ENDED]: PlayerCallback;
  [PlayerEvent.LOADED_METADATA]: PlayerCallback;
  [PlayerEvent.VOLUME_CHANGE]: PlayerCallback;
  [PlayerEvent.DURATION_CHANGE]: PlayerDurationChangeCallback;
  [PlayerEvent.ERROR]: PlayerErrorCallback;
}
