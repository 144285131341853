import {
  CalendarIcon,
  CdnIcon,
  CodeIcon,
  InfoIcon,
  LinkIcon,
  MediaIcon,
  MenuButton,
  MessageIcon,
  RecordingIcon,
  ShieldIcon,
  SubHeading,
  TextIcon,
  WorldIcon,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { NavLink, Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

import { useGetWebcastInformationQuery } from '../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../routes';
import { addParamToPath } from '../../../utils';
import managerStyles from '../../manager.module.css';

export function WebcastSetupPage() {
  const { webcastId = '' } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const routes = useAbsoluteRoutes();

  const paths = {
    eventDetails: addParamToPath(routes.webcastSetup_eventDetails, webcastId),
    messaging: addParamToPath(routes.webcastSetup_messaging, webcastId),
    eventDescription: addParamToPath(routes.webcastSetup_eventDescription, webcastId),
    eventRecording: addParamToPath(routes.webcastSetup_eventRecording, webcastId),
    language: addParamToPath(routes.webcastSetup_language, webcastId),
    embedding: addParamToPath(routes.webcastSetup_embedding, webcastId),
    viewerAccess: addParamToPath(routes.webcastSetup_viewerAccess, webcastId),
    protocolSettings: addParamToPath(routes.webcastSetup_protocolSettings, webcastId),
    ecdn: addParamToPath(routes.webcastSetup_ecdn, webcastId),
    setupSummary: addParamToPath(routes.webcastSetup_setupSummary, webcastId),
    media: addParamToPath(routes.webcastSetup_media, webcastId),
  };

  const { data, loading } = useGetWebcastInformationQuery({ variables: { webcastId }, skip: !webcastId });

  const webcast = data?.webcast;
  const hasWebcastId = webcastId !== '';
  const pageIsRestricted = pathname !== paths.eventDetails;
  const eventIsNewlyCreated = hasWebcastId && pageIsRestricted && loading;
  const ecdnAvailable = !loading && !!webcast?.ecdnSettings;

  if (eventIsNewlyCreated) return null;
  if (hasWebcastId && !loading && !webcast) return <Navigate replace to={routes.root} />;
  if (pageIsRestricted && !hasWebcastId) return <Navigate replace to={paths.eventDetails} />;
  if (!ecdnAvailable && pathname === paths.ecdn) return <Navigate replace to={paths.eventDetails} />;

  return (
    <section className={classNames(managerStyles.section, hasWebcastId && managerStyles.centered)}>
      <nav className={managerStyles.navigation} data-testid="webcast-setup-navigation">
        <SubHeading style={{ padding: 30 }}>{t('manager.webcastSetup.navigation.title')}</SubHeading>

        <MenuButton component={NavLink} to={paths.eventDetails} data-testid="webcast-setup-event-details-link">
          <CalendarIcon />
          {t('manager.webcastSetup.navigation.eventDetails')}
        </MenuButton>

        <MenuButton
          component={NavLink}
          to={paths.language}
          disabled={!hasWebcastId}
          data-testid="webcast-setup-language-link"
        >
          <WorldIcon />
          {t('manager.webcastSetup.navigation.language')}
        </MenuButton>

        <MenuButton
          component={NavLink}
          to={paths.eventDescription}
          disabled={!hasWebcastId}
          data-testid="webcast-setup-event-description-link"
        >
          <TextIcon />
          {t('manager.webcastSetup.navigation.eventDescription')}
        </MenuButton>

        <MenuButton
          component={NavLink}
          to={paths.media}
          disabled={!hasWebcastId}
          data-testid="webcast-setup-media-link"
        >
          <MediaIcon />
          {t('manager.webcastSetup.navigation.media')}
        </MenuButton>

        <MenuButton
          component={NavLink}
          to={paths.viewerAccess}
          disabled={!hasWebcastId}
          data-testid="webcast-setup-viewer-access-link"
        >
          <ShieldIcon />
          {t('manager.webcastSetup.navigation.viewerAccess')}
        </MenuButton>

        <MenuButton
          component={NavLink}
          to={paths.embedding}
          disabled={!hasWebcastId}
          data-testid="webcast-setup-embedding-link"
        >
          <CodeIcon />
          {t('manager.webcastSetup.navigation.embedding')}
        </MenuButton>

        <MenuButton
          component={NavLink}
          to={paths.messaging}
          disabled={!hasWebcastId}
          data-testid="webcast-setup-messaging-link"
        >
          <MessageIcon />
          {t('manager.webcastSetup.navigation.messaging')}
        </MenuButton>

        <MenuButton
          component={NavLink}
          to={paths.eventRecording}
          disabled={!hasWebcastId}
          data-testid="webcast-setup-event-recording"
        >
          <RecordingIcon />
          {t('manager.webcastSetup.navigation.eventRecording')}
        </MenuButton>

        <MenuButton
          component={NavLink}
          to={paths.protocolSettings}
          disabled={!hasWebcastId}
          data-testid="webcast-setup-protocol-settings-link"
        >
          <LinkIcon />
          {t('manager.webcastSetup.navigation.protocolSettings')}
        </MenuButton>

        {ecdnAvailable && (
          <MenuButton
            component={NavLink}
            to={paths.ecdn}
            disabled={!hasWebcastId}
            data-testid="webcast-setup-ecdn-link"
          >
            <CdnIcon />
            {t('manager.webcastSetup.navigation.ecdn')}
          </MenuButton>
        )}

        <MenuButton
          component={NavLink}
          to={paths.setupSummary}
          disabled={!hasWebcastId}
          data-testid="webcast-setup-summary-link"
        >
          <InfoIcon />
          {t('manager.webcastSetup.navigation.setupSummary')}
        </MenuButton>
      </nav>

      <Outlet />
    </section>
  );
}
