import { Heading, PrimaryButton, SecondaryButton, Tooltip } from '@movingimage-evp/mi-ui-component-library';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import { BasicEventInfo } from './basic-event-info';
import { EncoderInfo } from './encoder-info';
import { RecordingInfo } from './recording-info';
import { SharingInfo } from './sharing-info';
import {
  GetWebcastSetupSummaryDocument as GET_WEBCAST_SETUP_SUMMARY,
  State,
  useGetWebcastSetupSummaryQuery,
  useInitializeEventMutation,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { addParamToPath } from '../../../../utils';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

import styles from './setup-summary.module.css';

export function SetupSummaryPage() {
  const { webcastId = '' } = useParams();
  const { t } = useTranslation();
  const routes = useAbsoluteRoutes();
  const {
    isAccessToStudioAllowed,
    isAccessToStudioMessagingAllowed,
    isFullAccessToStudioAllowed,
    isEditorAndModerator,
  } = useUserPermissions();

  const { data } = useGetWebcastSetupSummaryQuery({ variables: { webcastId }, skip: !webcastId });
  const { state, sharingLink } = data?.webcast || {};

  const [initializeEventMutation, { loading, called }] = useInitializeEventMutation({
    refetchQueries: [GET_WEBCAST_SETUP_SUMMARY],
  });

  useEffect(() => {
    if (state === State.PRELIVE && isFullAccessToStudioAllowed) initializeEventMutation({ variables: { webcastId } });
  }, [webcastId, state, isFullAccessToStudioAllowed, initializeEventMutation]);

  const studioLinkDisabled = loading || (state === State.PRELIVE && !called) || !isAccessToStudioAllowed;
  const viewerRedirectionParam = sharingLink?.token ? `${webcastId}?hmac-token=${sharingLink.token}` : webcastId;

  return (
    <main className={managerStyles.main} data-testid="setup-summary-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.summary.title')}</Heading>

      <div className={styles.wrapper}>
        <BasicEventInfo />
        <SharingInfo />
        <RecordingInfo />
        <EncoderInfo />
      </div>

      <div className={managerStyles.buttons}>
        <Tooltip
          data-testid="open-studio-tooltip"
          label={t('manager.webcastSetup.notEditableMessage.role')}
          hidden={(isAccessToStudioAllowed && !isEditorAndModerator) || isFullAccessToStudioAllowed}
        >
          <PrimaryButton
            data-testid="open-studio"
            component={NavLink}
            to={addParamToPath(routes.studio, webcastId)}
            disabled={studioLinkDisabled || (isEditorAndModerator && !isFullAccessToStudioAllowed)}
            rel="noreferrer"
            target="_blank"
          >
            {t('manager.webcastSetup.summary.buttons.openStudio')}
          </PrimaryButton>
        </Tooltip>

        <SecondaryButton
          data-testid="open-viewer"
          component={NavLink}
          to={addParamToPath(routes.viewer, viewerRedirectionParam)}
          rel="noreferrer"
          target="_blank"
        >
          {t('manager.webcastSetup.summary.buttons.openViewer')}
        </SecondaryButton>

        {isEditorAndModerator && (
          <Tooltip
            data-testid="open-studio-messaging-tooltip"
            label={t('manager.webcastSetup.notEditableMessage.role')}
            hidden={isAccessToStudioMessagingAllowed}
          >
            <SecondaryButton
              data-testid="open-studio-messaging"
              component={NavLink}
              to={addParamToPath(routes.studio_messaging, webcastId)}
              disabled={!isAccessToStudioMessagingAllowed}
            >
              {t('manager.webcastSetup.summary.buttons.openStudioMessaging')}
            </SecondaryButton>
          </Tooltip>
        )}
      </div>
    </main>
  );
}
