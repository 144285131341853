// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer_wrapper__ws8sQ {
  margin-right: auto;
}

.timer_wrapper__ws8sQ span {
  color: var(--overlayForeground);

  font-size: 16px;
  line-height: 20px;
}

.timer_wrapper__ws8sQ > *:nth-child(2) {
  opacity: 0.7;
  margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/timer/timer.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;;EAE/B,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".wrapper {\n  margin-right: auto;\n}\n\n.wrapper span {\n  color: var(--overlayForeground);\n\n  font-size: 16px;\n  line-height: 20px;\n}\n\n.wrapper > *:nth-child(2) {\n  opacity: 0.7;\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `timer_wrapper__ws8sQ`
};
export default ___CSS_LOADER_EXPORT___;
