import type { BreakpointSettings, Breakpoint } from '../../../components/player/player.interface';
import { useMemo } from 'react';
import { definedProps } from '../../../utils/defined-properties';

interface ElementsVisibilities {
  showLogo: boolean;
  showTitle: boolean;
  showTopbar: boolean;
  showPlayButton: boolean;
  showTimeline: boolean;
  showVolumeInput: boolean;
  showVolumeSlider: boolean;
  showVideoLength: boolean;
  showLoopButton: boolean;
  showSubtitles: boolean;
  showSettingsButton: boolean;
  showFullscreenButton: boolean;
  showPlaybackSpeed: boolean;
  showBigPlayButton: boolean;
  showStepButtons: boolean;
  showChaptersButton: boolean;
  verticalVolumeSlider: boolean;
}

const defaultBreakpoints: Breakpoint[] = [
  {
    maxWidth: 639,
    settings: {
      stepButtonsAreVisible: false,
      verticalVolumeSlider: true,
    },
  },
  {
    maxWidth: 479,
    settings: {
      hideTimer: true,
    },
  },
  {
    maxWidth: 360,
    settings: {
      hideLoopButton: true,
      hideSettingsMenu: true,
      hideTopbar: true,
      hideChaptersButton: true,
    },
  },
];

/**
 * Returns the visibility of UI elements depending on the size of the player and initial configuration.
 * @param {number} [playerWidth] The current width of the player. @default [1280]
 * @param {BreakpointSettings} [settings] The initial configuration of the UI elements.
 * @param {Breakpoint[]} [breakpoints] The custom breakpoints to override the default configuration (or any default breakpoints implemented by the player).
 */
export function useElementsVisibilities(
  playerWidth = 1280,
  settings: BreakpointSettings = {},
  breakpoints: Breakpoint[] = []
): ElementsVisibilities {
  // Apply default breakpoints
  breakpoints = useMemo(() => [...defaultBreakpoints, ...breakpoints], [breakpoints]);

  // Apply breakpoint settings
  const currentSettings = useMemo(
    () =>
      breakpoints.reduce((currentSettings, breakpoint) => {
        if (
          (!breakpoint.minWidth || playerWidth >= breakpoint.minWidth) &&
          (!breakpoint.maxWidth || playerWidth <= breakpoint.maxWidth)
        ) {
          return { ...currentSettings, ...definedProps(breakpoint.settings) };
        }
        return currentSettings;
      }, settings || {}),
    [playerWidth, settings, breakpoints]
  );

  // Map current settings to elements visibilities
  const visibilities = useMemo(() => {
    return {
      showLogo: !currentSettings.hideLogo,
      showTitle: !currentSettings.hideTitle,
      showTopbar: !currentSettings.hideTopbar,
      showPlayButton: !currentSettings.hidePlayButton,
      showTimeline: !currentSettings.hideTimeline,
      showVolumeInput: !currentSettings.hideVolumeInput,
      showVolumeSlider: !currentSettings.hideVolumeInput,
      showCurrentTime: !currentSettings.hideTimer,
      showVideoLength: !currentSettings.hideTimer,
      showLoopButton: !currentSettings.hideLoopButton,
      showSubtitles: !currentSettings.hideLanguageMenu,
      showSettingsButton: !currentSettings.hideSettingsMenu && !currentSettings.hideMenu,
      showFullscreenButton: !currentSettings.hideFullscreenButton && !currentSettings.hideMenu,
      showPlaybackSpeed: !currentSettings.hidePlaybackSpeed,
      showBigPlayButton: !currentSettings.hideBigPlayButton,
      showChaptersButton: !currentSettings.hideChaptersButton,
      showStepButtons: !!currentSettings.stepButtonsAreVisible,
      verticalVolumeSlider: !!currentSettings.verticalVolumeSlider,
    } as ElementsVisibilities;
  }, [
    currentSettings.hideLogo,
    currentSettings.hideTitle,
    currentSettings.hideTopbar,
    currentSettings.hidePlayButton,
    currentSettings.hideTimeline,
    currentSettings.hideVolumeInput,
    currentSettings.hideTimer,
    currentSettings.hideLoopButton,
    currentSettings.hideLanguageMenu,
    currentSettings.hideSettingsMenu,
    currentSettings.hideMenu,
    currentSettings.hideFullscreenButton,
    currentSettings.hidePlaybackSpeed,
    currentSettings.hideBigPlayButton,
    currentSettings.hideChaptersButton,
    currentSettings.stepButtonsAreVisible,
    currentSettings.verticalVolumeSlider,
  ]);

  return visibilities;
}
