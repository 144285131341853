import { useParams } from 'react-router-dom';

export const relativeRoutes = {
  webcastSetup: 'webcast-setup',
  eventDetails: 'event-details',
  eventDescription: 'event-description',
  messaging: 'messaging',
  eventRecording: 'event-recording',
  language: 'language',
  embedding: 'embedding',
  viewerAccess: 'viewer-access',
  protocolSettings: 'protocol-settings',
  ecdn: 'ecdn',
  setupSummary: 'setup-summary',
  media: 'media',
  eventSummary: 'event-summary',
  channelSettings: 'channel-settings',
  planDetailsAndUsage: 'plan-details-and-usage',
  compliance: 'compliance',
  users: 'users',
  channels: 'channels',
  branding: 'branding',
  studio: 'studio',
  security: 'security',
};

export const normalize = (path: string) => path.replace(/\/+/g, '/');
export const join = (...strings: string[]) => normalize(strings.join('/'));

export const pathsWithoutChannelId = {
  viewer: '/view',
  iFrame: '/iframe',
  featureToggle: '/toggle',
  subtitlesDemo: '/subtitles-demo',
  vwDemo: '/vw-demo',
};

export function useAbsoluteRoutes() {
  const { channelId = '' } = useParams();
  const root = `/${channelId}`;
  const webcastSetup = join(root, relativeRoutes.webcastSetup);
  const eventSummary = join(root, relativeRoutes.eventSummary);
  const channelSettings = join(root, relativeRoutes.channelSettings);

  return {
    root,
    eventSummary,
    webcastSetup,
    webcastSetup_eventDetails: join(webcastSetup, relativeRoutes.eventDetails),
    webcastSetup_eventDescription: join(webcastSetup, relativeRoutes.eventDescription),
    webcastSetup_messaging: join(webcastSetup, relativeRoutes.messaging),
    webcastSetup_eventRecording: join(webcastSetup, relativeRoutes.eventRecording),
    webcastSetup_language: join(webcastSetup, relativeRoutes.language),
    webcastSetup_embedding: join(webcastSetup, relativeRoutes.embedding),
    webcastSetup_viewerAccess: join(webcastSetup, relativeRoutes.viewerAccess),
    webcastSetup_protocolSettings: join(webcastSetup, relativeRoutes.protocolSettings),
    webcastSetup_ecdn: join(webcastSetup, relativeRoutes.ecdn),
    webcastSetup_setupSummary: join(webcastSetup, relativeRoutes.setupSummary),
    webcastSetup_media: join(webcastSetup, relativeRoutes.media),
    channelSettings,
    channelSettings_planDetailsAndUsage: join(channelSettings, relativeRoutes.planDetailsAndUsage),
    channelSettings_compliance: join(channelSettings, relativeRoutes.compliance),
    channelSettings_security: join(channelSettings, relativeRoutes.security),
    users: join(root, relativeRoutes.users),
    channels: join(root, relativeRoutes.channels),
    branding: join(root, relativeRoutes.branding),
    studio: join(root, relativeRoutes.studio),
    studio_messaging: join(root, relativeRoutes.studio, relativeRoutes.messaging),
    ...pathsWithoutChannelId,
  };
}
