import { Heading, Paragraph, SelectionInputLayout, Spinner, Toggle } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  ECdnType,
  State,
  useGetWebcastEcdnSettingsQuery,
  useUpdateWebcastEcdnMutation,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

export function EcdnPage() {
  const { t } = useTranslation();
  const { webcastId = '' } = useParams();
  const routes = useAbsoluteRoutes();
  const { isEventEditingAllowed } = useUserPermissions();

  const ecdnSettingsResponse = useGetWebcastEcdnSettingsQuery({
    variables: { webcastId },
    skip: !webcastId,
  });

  const [updateWebcastEcdn, updateWebcastEcdnResponse] = useUpdateWebcastEcdnMutation();

  const formDisabled = ecdnSettingsResponse.data?.webcast.state !== State.PRELIVE || !isEventEditingAllowed;
  const ecdnSettings = ecdnSettingsResponse.data?.webcast.ecdnSettings;
  const ecdnActive = ecdnSettings?.isActive;

  const isHive = ecdnSettings?.type === ECdnType.HIVE;
  const vBrickP2P = ecdnSettings?.type === ECdnType.VBRICK_P2P;
  const vBrickOmnicache = ecdnSettings?.type === ECdnType.VBRICK_OMNICACHE;
  const vBrickP2PAndOmnicache = ecdnSettings?.type === ECdnType.VBRICK_P2P_AND_OMNICACHE;
  const isVBrick = vBrickP2P || vBrickOmnicache || vBrickP2PAndOmnicache;

  const hiveEnabled = isHive && ecdnActive;
  const vBrickP2PEnabled = (vBrickP2P || vBrickP2PAndOmnicache) && ecdnActive;
  const vBrickOmnicacheEnabled = (vBrickOmnicache || vBrickP2PAndOmnicache) && ecdnActive;

  const handleToggleEcdn = (type: ECdnType, enabled: boolean) => {
    let newType = type;
    let newEnabled = enabled;

    if (vBrickP2PAndOmnicache && !enabled) {
      newType = type === ECdnType.VBRICK_P2P ? ECdnType.VBRICK_OMNICACHE : ECdnType.VBRICK_P2P;
      newEnabled = true;
    } else if ((!vBrickP2PAndOmnicache && enabled) || !enabled) {
      newType = ECdnType.VBRICK_P2P_AND_OMNICACHE;
    }

    updateWebcastEcdn({
      variables: {
        input: {
          id: webcastId,
          ecdnSettings: { type: newType, enabled: newEnabled },
        },
      },
    });
  };

  if (ecdnSettingsResponse.loading) return <Spinner size={40} style={{ margin: 'auto' }} />;

  return (
    <main className={managerStyles.main} data-testid="ecdn-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.ecdn.title')}</Heading>
      <Paragraph className={managerStyles.grayText}>{t('manager.webcastSetup.ecdn.description')}</Paragraph>

      <div>
        {isHive && (
          <SelectionInputLayout
            checked={hiveEnabled}
            disabled={formDisabled}
            hintMessage={t(`manager.webcastSetup.ecdn.options.${ECdnType.HIVE}.hint`)}
            inputElement={
              <Toggle
                data-testid="enable-hive-toggle"
                checked={hiveEnabled}
                disabled={formDisabled}
                onChange={() => handleToggleEcdn(ECdnType.HIVE, !hiveEnabled)}
              >
                {t(`manager.webcastSetup.ecdn.options.${ECdnType.HIVE}.label`)}
              </Toggle>
            }
          />
        )}

        {isVBrick && (
          <SelectionInputLayout
            checked={vBrickP2PEnabled}
            disabled={formDisabled}
            hintMessage={t(`manager.webcastSetup.ecdn.options.${ECdnType.VBRICK_P2P}.hint`)}
            inputElement={
              <Toggle
                data-testid="enable-vbrick-p2p-toggle"
                checked={vBrickP2PEnabled}
                disabled={formDisabled}
                onChange={() => handleToggleEcdn(ECdnType.VBRICK_P2P, !vBrickP2PEnabled)}
              >
                {t(`manager.webcastSetup.ecdn.options.${ECdnType.VBRICK_P2P}.label`)}
              </Toggle>
            }
          />
        )}

        {isVBrick && (
          <SelectionInputLayout
            checked={vBrickOmnicacheEnabled}
            disabled={formDisabled}
            hintMessage={t(`manager.webcastSetup.ecdn.options.${ECdnType.VBRICK_OMNICACHE}.hint`)}
            inputElement={
              <Toggle
                data-testid="enable-vbrick-omnicache-toggle"
                checked={vBrickOmnicacheEnabled}
                disabled={formDisabled}
                onChange={() => handleToggleEcdn(ECdnType.VBRICK_OMNICACHE, !vBrickOmnicacheEnabled)}
              >
                {t(`manager.webcastSetup.ecdn.options.${ECdnType.VBRICK_OMNICACHE}.label`)}
              </Toggle>
            }
          />
        )}
      </div>

      <SetupPageFooter
        nextLabel={t('manager.webcastSetup.ecdn.nextStep.label')}
        route={routes.webcastSetup_setupSummary}
        saving={updateWebcastEcdnResponse.loading}
      />
    </main>
  );
}
